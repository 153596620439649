<template>
  <div class="content_box">
    <div class="content_box_return">
      <i class="iconfont" @click="levelBut">&#xe625;</i>
      <div class="content_box_title" @click="levelBut">返回</div>
    </div>
    <div class="content_empty" v-if="!empty">
      <div class="content_box_details">
        <div class="content_box_details_tittle">
          {{ helpDetails.title }}
        </div>
        <div v-html="helpDetails.content"></div>
      </div>
    </div>
    <div v-else class="content_box_empty">
        <empty-status
      :img="require('@/assets/img/emptyIcon/wushouchang@2x.png')"
    />
    </div>
  </div>
</template>

<script>
import { getHelpDetails } from "@/api/personal";
import emptyStatus from "@/components/emptyStatus/index";
export default {
  components: {
    emptyStatus,
  },
  data() {
    return {
      helpDetails: {}, //帮助详情
      empty: false,
    };
  },
  created() {
    this.getParameter();
  },
  methods: {
    //获取页面参数
    getParameter() {
      let pageId = this.$route.query.id || "0";
      console.log(pageId);
      if (pageId) {
        this.getHelpDetails(pageId);
      }
    },
    //获取帮助详情
    getHelpDetails(id) {
      getHelpDetails(id).then((res) => {
        if (res.code == 200) {
          if (!res.data) {
            this.empty = true;
          } else {
            this.empty = false;
          }
          this.helpDetails = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //返回常见问题页
    levelBut() {
      this.optionBut = 0;
      this.optionClass = 0;
      this.$router.push({ path: "helpCenter", query: { option: 0 } });
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  width: 100%;
  text-align: left;
  & > .content_box_return {
    line-height: 60px;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eeeeee;
    & > .iconfont {
      font-size: 16px;
      margin-left: 33px;
      cursor: pointer;
      float: left;
    }
    & > .content_box_title {
      padding-left: 10px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
      float: left;
    }
  }
  //常见问题详情
  & > .content_empty {
    //常见问题详情内容
    & > .content_box_details {
      padding: 35px 36px;
      text-align: left;
      & > .content_box_details_tittle {
        font-size: 22px;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
        margin-bottom: 41px;
      }
    }
  }
}

//空
.content_box_empty {
    width: 100%;
    height: 420px;
}
</style>