<template>
  <div class="page">
    <div class="container">
      <!-- 左边菜单栏 -->
      <div class="navigation">
        <ul>
          <li
            v-for="(item, index) in navigation"
            :key="index"
            @click="optionButs(index, item.path)"
            :class="optionClass == index ? 'navigation_select' : ''"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <!-- 中间内容 -->
      <div class="content">
        <components
          :is="componentName"
          @detailsBut="detailsBut"
          haveTitle
        ></components>
      </div>
    </div>
  </div>
</template>

<script>
import registerAgreement from "@/components/agreement/registerAgreement";
import serviceAgreement from "@/components/agreement/serviceAgreement";
import privacyPolicy from "@/components/agreement/privacyPolicy";
import index from "@/components/helpCenter/index";
import helpCentetDetails from "@/components/helpCenter/helpCentetDetails";
import feedback from "@/components/helpCenter/feedback";

export default {
  components: {
    index,
    helpCentetDetails,
    feedback,
    registerAgreement,
    serviceAgreement,
    privacyPolicy,
  },
  data() {
    return {
      optionBut: 0, //切换模块
      optionClass: 0, //切换的颜色
      navigation: [
        {
          id: 0,
          text: "帮助中心",
          path: "index",
        },
        {
          id: 1,
          text: "意见反馈",
          path: "feedback",
        },
        {
          id: 2,
          text: "注册协议",
          path: "registerAgreement",
        },
        {
          id: 3,
          text: "服务协议",
          path: "serviceAgreement",
        },
        {
          id: 4,
          text: "隐私政策",
          path: "privacyPolicy",
        },
      ],
      componentName: "helpCenter",
    };
  },
  watch: {
    $route: function () {
      this.getParameter();
    },
  },
  created() {
    this.getParameter();
  },
  methods: {
    //左侧导航栏点击
    optionButs(e, path) {
      this.$router.push({
        path: "helpCenter",
        query: { option: e, path: path },
      });
      this.componentName = path;
      this.optionClass = e;
    },
    //获取页面参数
    getParameter() {
      let pageId = this.$route.query.option || "0";
      let path = this.$route.query.path || "index";
      this.optionClass = pageId;
      this.componentName = path;
    },
    // 常见问题详情
    detailsBut(id) {
      this.optionClass = 0;
      this.componentName = "helpCentetDetails";
      this.$router.push({
        path: "helpCenter",
        query: { option: 5, path: "helpCentetDetails", id: id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 693px;
  background: #f3f3f3;
  padding-top: 30px;
}

.navigation {
  width: 220px;
  min-height: 340px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px 0;
  float: left;
  & > ul {
    padding: 0;
    & > li {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
      margin-left: 3px;
      margin-bottom: 8px;
    }
    & > .navigation_select {
      background: #f6f6f6;
      border-left: 3px solid #0d79ff;
      color: #0d79ff;
      margin-left: 0;
    }
  }
}

.content {
  margin-left: 16px;
  background: #ffffff;
  width: 964px;
  min-height: 340px;
  border-radius: 6px;
  float: right;
  margin-bottom: 50px;
}
</style>