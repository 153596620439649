<template>
  <div class="content_box">
    <div class="content_box_title" v-show="haveTitle">隐私政策</div>
    <div class="education_content_sty">
      <h1 class="title_sty">隐私政策</h1>
      <p>
        本平台尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本平台会按照本隐私权政策的规定使用和披露您的个人信息。但本平台将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本平台不会将这些信息对外披露或向第三方提供。本平台会不时更新本隐私权政策。
        您在同意本平台服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本平台服务使用协议不可分割的一部分。
      </p>
      <h2>1. 适用范围</h2>
      <p>
        a)
        在您使用本平台服务时，本平台自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的数据；
      </p>
      <p>b) 本平台通过合法途径从商业伙伴处取得的用户个人数据。</p>
      <p>您了解并同意，以下信息不适用本隐私权政策：</p>
      <p>c) 您在使用本平台提供的搜索服务时输入的关键字信息；</p>
      <p>
        d)
        本平台收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；
      </p>
      <p>d) 违反法律规定或违反本平台规则行为及本平台已对您采取的措施。</p>
      <h2>2. 信息使用</h2>
      <p>
        a)
        本平台不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本平台（含本平台关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
      </p>
      <p>
        b)
        本平台亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。
      </p>
      <p>
        c)
        为服务用户的目的，本平台可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本平台合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
      </p>
      <h2>3. 信息披露</h2>
      <p>
        在如下情况下，本平台将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
      </p>
      <p>a) 经您事先同意，向第三方披露；</p>
      <p>b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
      <p>
        c)
        根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
      </p>
      <p>
        d)
        如您出现违反中国有关法律、法规或者本平台服务协议或相关规则的情况，需要向第三方披露；
      </p>
      <p>
        e)
        如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
      </p>
      <p>f) 其它本平台根据法律、法规或者网站政策认为合适的披露。</p>
      <h2>4. 信息存储和交换</h2>
      <p>
        本平台收集的有关您的信息和资料将保存在本平台及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本平台收集信息和资料所在地的境外并在境外被访问、存储和展示。
      </p>
      <h2>5. 信息安全</h2>
      <p>
        a)
        本平台帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本平台将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacyPolicy",
  props: {
    // 是否有标题
    haveTitle: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style lang="less" scoped>
.content_box {
  .content_box_title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    height: 60px;
    text-align: left;
    padding-left: 33px;
    border-bottom: 1px solid #eeeeee;
  }
}

.title_sty {
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}
.education_content_sty {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: justify;
  & > h2 {
    font-size: 16px;
    margin-top: 20px;
  }
  & > h3 {
    font-size: 14px;
    margin-top: 10px;
  }
  & > p {
    font-size: 12px;
    text-indent: 24px;
    margin-top: 10px;
  }
}
</style>