<template>
  <div class="content_box">
    <div class="content_box_title" v-show="haveTitle">注册协议</div>
    <div class="education_content_sty">
      <h1 class="title_sty">注册协议</h1>
      <p>
        为维护您自身权益，请您仔细阅读本协议，您点击“同意并继续”按钮后，本协议即构成对双方具有约束力的法律文件；阅读本协议过程中，如您不同意本协议或其中任何条款的约定，您应立即停止注册程序。
      </p>
      <h2>一、账户注册、注册信息的保管与使用</h2>
      <h3>1.1、注册者资格</h3>
      <p>
        您确认，在您开始注册程序使用本平台服务前，您是具备中华人民共和国法律规定的完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的监护人应承担因此而导致的一切后果。
      </p>
      <h3>1.2、账户说明</h3>
      <p>
        1.2.1、当您按照注册页面提示填写信息、阅读并同意本协议且完成会员注册程序后，您才能成为本平台会员。
      </p>
      <p>
        1.2.2、您确认，您所填写/提供的注册资料是真实、准确、完整、合法有效的，且您保证本平台可以通过该等信息与您本人进行联系。如果您的注册信息有变更，请及时予以更新。如您提供的注册资料不合法、不真实、不准确、不详尽，因此而引起的责任、后果将全部由您承担。
      </p>
      <h3>1.3、注册信息的保管与使用</h3>
      <p>
        1.3.1、您提供的注册信息，本平台将按照中华人民共和国法律、法规相关的规定，采取技术和制度等手段为您妥善保管。除非得到您的授权或者按照法律及程序要求进行透露外，本平台将保证您的信息安全。
      </p>
      <p>
        1.3.2、本平台承诺仅根据本平台的服务目的使用您的信息。服务目的包括但不限于交易确认、客户回访、政策更新、发送促销信息等，除此之外，本平台将对您的信息保密。如您认为平台超越了正常服务范围使用了您的信息，请您立即与本平台的客服取得联系，本平台将以七天之内给予核实、纠正。
      </p>
      <p>
        1.3.3、在您注册成功后，将产生用户名和密码等账户信息，您的账户信息由您保管，您可以根据本平台的规定改变您的密码等账户信息。本平台任何时候均不会主动要求您提供您的账户。因此，请您务必保管好您的账户，谨慎合理的使用您的用户名和密码。如果因为您主动泄露、遭受他人攻击、诈骗或因管理不善造成账户（包括但不限于注册账户和密码、支付账户和密码等）被复制、盗用及其他行为而导致的损失及后果，均由您自行承担。
      </p>
      <p>
        1.3.4、您不得将在本平台注册获得的账户转让或转借给他人使用，否则，由此产生的全部责任均由您承担。
      </p>
      <p>
        1.3.5、除本平台存在过错外，您应对您账户下的所有行为后果（包括但不限于在线签署各类协议、发布信息、出售或购买商品、提供或接受物流运输服务、披露信息等）负责。
      </p>
      <h2>二、本平台的权利和义务</h2>
      <p>
        2.1、本平台有义务在现有技术上维护整个平台网站的正常运行，保障交易数据的安全、可靠，按约定的服务内容和标准向您提供服务，并保证努力提升和改进技术。
      </p>
      <p>
        2.2、若您在本平台做出下列行为的，本平台有权做出相应的处理，并且无需事先通知您或取得您的同意：
      </p>
      <p>
        ①发布或以电子邮件或以其他方式传送存在欺诈、虚假等恶意内容的信息或侵犯公共利益、可能严重损害本平台利益、侵犯其他用户合法利益及他人人身财产权利内容的信息；
      </p>
      <p>②进行与本平台活动无关为目的的操作；</p>
      <p>③干扰或破坏本平台或与本平台相连接的服务器和网络；</p>
      <p>
        2.3、经国家法律生效法律文件或行政处罚决定确认您存在违法违规行为或者有足够事实依据可以认定您存在违法或违反本协议行为的，本平台有权在网站公布您的违法或违规行为并要求您承担相应的赔偿责任或违约责任。
      </p>
      <p>
        2.4、若您在本平台的活动与其他用户产生纠纷或诉讼的，用户通过司法机关或行政机关依照法定程序要求本平台提供相关数据，本平台应积极配合并提供有关资料。
      </p>
      <h2>三、会员的权利和义务</h2>
      <p>
        3.1、会员有权根据本协议的约定，以及本平台网站上发布的相关规则在本平台上的活动。
      </p>
      <p>
        3.2、会员应当保证在网站活动过程中，遵守诚实守信用的原则，不扰乱网上交流、交易的正常秩序。
      </p>
      <p>3.3、会员同意严格遵守以下义务：</p>
      <p>
        ①不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论、煽动颠覆国家的政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论、煽动民族仇恨、民族歧视、破坏民族团结的言论；
      </p>
      <p>②不得利用本平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</p>
      <p>③不得捏造或者扭曲事实，散布谣言，扰乱社会秩序；</p>
      <p>
        ④不得传输或发表任何封建迷信、邪教、淫秽、色情、赌博、暴力、恐怖、教唆犯罪等不文明的信息资料；
      </p>
      <p>⑤不得公然侮辱他人或者捏造事实诽谤他人或者进行其他恶意攻击；</p>
      <p>⑥不得违反宪法和法律、行政法规规定。</p>
      <p>3.4、未经本平台书面同意，会员不得在本平台上发布任何形式的广告。</p>
      <p>3.5、遵守本平台制定的规则。</p>
      <h2>四、责任限制</h2>
      <p>
        4.1、本平台仅在“按现状”和“按现有”的基础上向用户提供服务。但无法对由于信息网络设备维护、连接故障、电脑、通讯或其他系统的故障、罢工、暴乱、骚乱、灾难性天气（如火灾、洪水、风暴等）、爆炸、战争、政府行为、司法行政机关的命令或因为第三方原因而造成的损害结果承担责任。
      </p>
      <p>
        4.2、在法律法规或所允许的限度内，因使用本平台服务而引起的任何损害紧急损失，本平台承担的全部责任均不超过就用户出售/购买的与该索赔有关的商品、提供/接受的运输服务等所实际获得的直接利益。
      </p>
      <h2>五、知识产权</h2>
      <p>
        5.1、本平台所刊登的资料信息（如文字、图片、标识、按钮图标、图像、数据编辑和软件）均是本平台运营方及其关联方的财产，受中国和国际知识产权法的保护。
      </p>
      <p>
        5.2、除法律另有强制性规定外，未经本平台运营方明确的特别书面许可，任何单位或个人均不得以任何方式非法的全部复制或部分复制、转载、引用或用作其他用途。
      </p>
      <p>
        5.3、会员在本平台上发表的任何文章、言论仅代表其个人观点，与本平台无关，因此产生的人责任由作者本人承担。
      </p>
      <h2>六、变更事项</h2>
      <p>
        本平台可以根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改、补充协议。变更以后的协议、补充协议将通过法定程序并以本协议约定的方式通知您。
      </p>
      <p>
        如果您不同意变更事项，您有权于变更事项确立的生效日前联系本平台客服反馈意见。如反馈意见得以采纳，本平台将斟酌并调整变更事项。
      </p>
      <p>
        如果您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用本平台服务，变更事项对您不产生效力；如果您在变更事项生效后仍继续使用本平台服务，则视为您同意已生效的变更事项。
      </p>
      <h2>七、通知</h2>
      <p>（一）公示的文案；</p>
      <p>（二）站内信、客户推送的消息；</p>
      <p>（三）根据您预留于本平台的联系方式发出的电子邮件、短信、函件等。</p>
      <h2>八、协议终止</h2>
      <p>
        8.1、会员有权向本平台提出注销账户的申请，经过本平台运营方审核同意后，由本平台注销该申请注销的账户，则本协议即告终停止。账户注销后，本平台没有义务为申请注销者保留或披露账户中的任何信息。
      </p>
      <p>
        8.2、出现下列情况时，本平台可以通过注销您的账户的方始单方解除本协议：
      </p>
      <p>①您违反本协议约定、本平台运营者依据违约条款终止本协议的；</p>
      <p>
        ②您盗用他人账户、发布违禁信息、骗取他人财物、销售假冒伪劣产品、扰乱市场秩序、采取不正当手段牟利等行为，本平台对您的账户予以查封的；
      </p>
      <p>
        ③除上述情形外，因为您多次违反本平台的相关规定且情节严重，本平台将对您的账户予以查封的；
      </p>
      <p>④本平台认为需要终止本协议的其他情况。</p>
      <h2>8.3、本协议终止后，本平台仍享有以下权利：</h2>
      <p>①继续保留您的注册信息及您使用本平台服务期间的所有交易信息；</p>
      <p>
        ②您在本平台服务期间存在违法行为或违反本协议的行为的，本平台运营者仍有追究您责任的权利。
      </p>
      <h2>九、法律适用、争议解决及其他</h2>
      <p>
        9.1、法律适用：本协议的订立、执行、解释、修订、补充及争议均适用中华人民共和国法律（不包括香港、澳门、台湾地区）。如法律无相关规定的，参照商业惯例或行业惯例。
      </p>
      <p>
        9.2、争议解决：本协议履行过程中若发生争议，由本平台与您友好协商解决。协商不成时，任何一方均可向本平台所有者住所地人民法院提起诉讼。
      </p>
      <p>9.3、本协议内容中黑体、加粗等方式显著标识的条款，请您着重阅读。</p>
      <h2>十、协议的修改</h2>
      <p>
        10.1、本平台有权随时修改本协议的任何条款，一旦本协议的内容发生变动，本平台将会直接在本平台网站上公布修改之后的协议内容，该公布行为视为本平台已经通知用户修改内容。本平台也可以通过其他适当方式向用户提示修改内容。
      </p>
      <p>
        10.2、如果不同意本协议相关条款所做的修改，用户有权停止使用网络服务。如果继续使用网络服务，则视为用户接受本平台对本协议相关条款所做的修改。
      </p>
      <p>
        本协议签署（注册账户即签署）后，一律视为您完全接受本协议，本协议立即生效。故您在签署（注册）前，请您务必确认悉知并完全理解本协议的全部内容。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "registerAgreement",
  props: {
    // 是否有标题
    haveTitle: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style lang="less" scoped>
.content_box {
  .content_box_title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    height: 60px;
    text-align: left;
    padding-left: 33px;
    border-bottom: 1px solid #eeeeee;
  }
}
.title_sty {
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}
.education_content_sty {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: justify;
  & > h2 {
    font-size: 16px;
    margin-top: 20px;
  }
  & > h3 {
    font-size: 14px;
    margin-top: 10px;
  }
  & > p {
    font-size: 12px;
    text-indent: 24px;
    margin-top: 10px;
  }
}
</style>