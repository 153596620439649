<template>
  <div class="content_box">
    <div class="content_box_title">意见反馈</div>
    <div class="content_box_opinion">
      <div class="content_box_opinion_title">具体信息：</div>
      <span>
        <textarea
          placeholder="请输入您的意见"
          :maxlength="textareaMaxLength"
          v-model="textareaValue"
        ></textarea>
        <span>{{ textareaValue.length }}/{{textareaMaxLength}}</span>
      </span>
      <div class="content_box_opinion_but">
        <button class="content_box_opinion_elbut" @click="submitBut">
          提交
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { submitFeedback } from "@/api/personal";
export default {
  data() {
    return {
      textareaValue: "",
      textareaMaxLength: 140
    };
  },
  created() {},
  methods: {
    //意见提交
    submitBut() {
      if (this.textareaValue == "") {
        this.$message.error("输入的内容不能为空");
        return;
      }
      submitFeedback(this.textareaValue).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "提交成功",
          });
          this.textareaValue = "";
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  width: 100%;
  text-align: left;
  & > .content_box_title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    height: 60px;
    text-align: left;
    padding-left: 33px;
    border-bottom: 1px solid #eeeeee;
  }
  //意见
  & > .content_box_opinion {
    padding: 23px 32px;
    & > .content_box_opinion_title {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 14px;
      text-align: left;
    }
    & > span {
      position: relative;
      & > textarea {
        margin-top: 13px;
        padding: 15px 13px;
        width: 100%;
        max-width: 100%;
        min-height: 147px;
        border: 1px solid #e8e8e8;
        outline: none;
      }
      & > span {
        position: absolute;
        right: 13px;
        bottom: 13px;
      }
    }

    & > .content_box_opinion_but {
      margin-top: 24px;
      text-align: right;
      & > .content_box_opinion_elbut {
        width: 113px;
        height: 36px;
        background: #0d79ff;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
</style>