<template>
  <div class="content_box">
    <div class="content_box_title">常见问题</div>
    <div class="content_box_list" v-if="!empty">
      <ul>
        <!-- <h3>一、关于账号、手机号、登录和用户名:</h3> -->
        <li
        @click="detailsBut(item.articleId)"
        v-for="(item, index) in helpArr"
        :key="index"
      >
        {{ index + 1 }}. {{ item.title }}
      </li>
        <!-- <div class="ul_line"></div> -->
      </ul>
      <div class="my_collection_paging">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next, jumper"
          :page-size="params.pageSize"
          :current-page.sync="params.pageNum"
          :total="total"
          @current-change="appraisesPageChange"
        ></el-pagination>
      </div>
    </div>
    <div v-else class="content_box_empty">
      <empty-status
        :img="require('@/assets/img/emptyIcon/wushouchang@2x.png')"
      />
    </div>
  </div>
</template>

<script>
import { getHelp } from "@/api/personal";
import emptyStatus from "@/components/emptyStatus/index";
export default {
  components: {
    emptyStatus,
  },
  data() {
    return {
      helpArr: [],
      empty: false,
      params: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
    };
  },
  created() {
    this.getHelp();
  },
  methods: {
    //获取帮助列表
    getHelp() {
      getHelp(this.parmas).then((res) => {
        if (res.code == 200) {
          if (res.total <= 0) {
            this.empty = true;
          } else {
            this.empty = false;
          }
          this.helpArr = res.rows;
          this.total = res.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 常见问题详情
    detailsBut(id) {
      this.$emit("detailsBut", id);
    },
    //改变页
    appraisesPageChange() {
      this.getHelp();
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  width: 100%;
  text-align: left;
  & > .content_box_title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    height: 60px;
    text-align: left;
    padding-left: 33px;
    border-bottom: 1px solid #eeeeee;
  }
  & > .content_box_list {
    & > ul {
      padding: 25px 32px 0;
      text-align: left;
      & > h3 {
        margin-left: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
        margin-bottom: 26px;
      }
      & > li {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: #555555;
        line-height: 14px;
        margin-bottom: 16px;
        margin-left: 3px;
        &:hover {
          color: #0d79ff;
        }
      }
      & > .ul_line {
        width: 100%;
        height: 1px;
        background: #eeeeee;
        margin-top: 29px;
      }
    }

    //分页
    .my_collection_paging {
        margin-top: 26px;
      padding: 0 30px 26px;
      text-align: right;
      height: 58px;
      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #0d79ff;
      }
    }
  }
}

//空
.content_box_empty {
  width: 100%;
  height: 420px;
}
</style>