<template>
  <div class="content_box">
    <div class="content_box_title" v-show="haveTitle">服务协议</div>
    <div class="education_content_sty">
      <h1 class="title_sty">服务协议</h1>
      <p>
        欢迎你使用万慧教育平台服务！为使用万慧教育平台服务（以下简称“本服务”），你应当阅读并遵守《万慧教育平台服务协议》（以下简称“本协议”），以及专项规则等。请你务必审慎阅读、充分理解各条款内容，特别是免除或制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免责条款可能以加粗形式提示你注意。除非你已阅读并接受本协议所有条款，否则你无权使用万慧教育服务。你对本服务的登录、查看、发布信息等行为即视为你已阅读并同意本协议的约束。
      </p>
      <h2>一、【协议的范围】</h2>
      <p>
        1.1
        本协议是你与万慧教育之间关于你使用万慧教育平台服务所订立的协议。“万慧教育”是指厦门万慧教育信息科技有限公司。
      </p>
      <p>
        1.2“万慧教育平台用户”是指注册、购买万慧教育平台服务并获得云学习管理权限的个人或组织，在本协议中更多地称为“你”。“最终用户”是指使用万慧教育平台用户购买云学习服务后获得分配使用的个人用户。前述“万慧教育平台用户”及“最终用户”统称为“用户”或“所有用户”。本协议内容同时包括万慧教育可能不断发布的关于本服务的相关协议、服务声明、业务规则及公告指引等内容（以下统称为“专项规则”）。上述内容一经正式发布，即为本协议不可分割的组成部分，所有用户均应同样予以遵守。
      </p>
      <h2>二、【关于本服务】</h2>
      <p>2.1 【本服务的获取】</p>
      <p>2.1.1 你可以向得到万慧教育授权的经销商购买本服务。</p>
      <p>
        2.1.2
        如果你通过未经万慧教育授权的第三方获取本服务，万慧教育无法保证本服务能够正常使用，并对你因此遭受的损失不予负责。
      </p>
      <p>
        2.1.3
        万慧教育对于你通过授权经销商提交的订单会尽快完成审核，审核结果将通过电子邮件、手机短信或授权经销商等其他方式通知你指定的联系人。
      </p>
      <p>
        2.1.4
        你理解并同意：你按照相关流程、提示提交订单需求并确认相关订单信息后，你的订单信息、你所提交的公司信息和资质、发票信息等任何信息将不可再进行任何修改，同时
        ，你应严格按照要求完成付款。
      </p>
      <p>
        2.1.5
        你向万慧教育授权的经销商购买本服务，万慧教育将按照本协议以及专项规则的规定向你提供服务。超越前述协议或规则的约定（如你向经销商特别定制的服务），你应向经销商请求履行，万慧教育并不保证前述约定得以履行。
      </p>
      <h2>2.2 【本服务的内容】</h2>
      <p>
        2.2.1
        本服务是万慧教育向用户提供云学习存储空间，并利用云学习系统为用户提供在线学习、考试与互动，上传、下载课件与试题，进行课件内容托管以及其他相关在线学习功能的服务。
      </p>
      <p>
        2.2.2
        在你成功购买本服务并开通后，万慧教育以你购买的最终用户数为基础，向你提供云学习的管理权限、App使用说明、相应的存储空间和使用说明等，并保证本服务的正常运行。
      </p>
      <p>
        2.2.3
        你向万慧教育授权的经销商购买本服务，服务起始日以你与经销商的约定为准。
      </p>
      <h2>三、【用户的权利义务】</h2>
      <p>3.1 【获取本服务的准备】</p>
      <p>
        3.1.1
        你应按照要求提交清晰完整的申请资料，包括但不限于：主体资格证明、申请人信息、企业联系人信息、发票信息等信息，具体申请资料以万慧教育要求的为准。如因资料缺失导致无法正常开通服务或延期开通的，万慧教育不承担任何责任。你保证提交的信息真实、准确、及时、完整，否则由此引发直接或间接的纠纷，所涉及的任何责任或赔偿均由你承担。你同意万慧教育可采用电话、邮件、或上门拜访等方式进行确认。
      </p>
      <p>
        3.1.3
        你需自行准备接受本服务所需的硬件设备并开通互联网连接服务，本服务使用过程中可能产生的硬件成本和数据流量费用，用户需自行承担，如因你缺少适当的硬件，或未成功连接互联网导致本服务无法正常使用，万慧教育不承担任何责任。
      </p>
      <h2>3.2 【用户权利】</h2>
      <p>
        3.2.1
        万慧教育平台用户享有在成功购买并开通本服务后，按照约定使用本服务的权利。
      </p>
      <p>
        3.2.2
        万慧教育平台用户拥有在服务约定范围内对最终用户管理的权利，包括：在购买的最终用户数范围内增加或减少最终用户、分配最终用户云学习空间、设置最终用户使用权限和密码等。
      </p>
      <p>3.2.3 最终用户享有按照万慧教育平台用户的设置使用本服务的权利。</p>
      <h2>3.3 【用户信息内容规范】</h2>
      <p>
        3.3.1
        本条所述信息内容是指用户使用本服务过程中所制作、复制、发布、传播的任何内容，包括但不限于课程内容、标题，以及其他使用本服务所产生的内容。
      </p>
      <p>
        3.3.2
        万慧教育一直致力于为用户提供文明健康、规范有序的网络环境，所有用户均不得利用本服务制作、复制、发布、传播如下干扰万慧教育正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：
      </p>
      <p>3.3.2.1 发布、传送、传播、储存违反国家法律法规禁止的内容：</p>
      <p>（1）违反宪法确定的基本原则的；</p>
      <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
      <p>（3）损害国家荣誉和利益的；</p>
      <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
      <p>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
      <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
      <p>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>
      <p>（10）以非法民间组织名义活动的；</p>
      <p>（11）含有法律、行政法规禁止的其他内容的。</p>
      <p>
        3.3.2.2
        发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
      </p>
      <p>3.3.2.3 涉及他人隐私、个人信息或资料的；</p>
      <p>
        3.3.2.4
        其他违反法律法规、政策及公序良俗、社会公德或干扰万慧教育正常运营和侵犯其他用户或第三方合法权益内容的信息。
      </p>
      <h2>3.4 【服务使用规范】</h2>
      <p>
        除非法律允许或万慧教育书面许可，你使用本服务过程中不得从事下列行为：
      </p>
      <p>
        3.4.1
        对本服务进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本服务的源代码；
      </p>
      <p>
        3.4.2
        对万慧教育拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
      </p>
      <p>
        3.4.3
        通过非万慧教育开发、授权的第三方软件、插件、外挂、系统，登录或使用万慧教育服务，或制作、发布、传播上述工具；
      </p>
      <p>
        3.4.4 自行或者授权他人、第三方软件对本服务及其组件、模块、数据进行干扰；
      </p>
      <p>3.4.5 其他未经万慧教育明示授权的行为。</p>
      <h2>3.5 【服务运营规范】</h2>
      <p>
        除非法律允许或万慧教育书面许可，所有用户在使用本服务过程中均不得从事下列行为：
      </p>
      <p>3.5.1 提交、发布虚假信息，或冒充、利用他人名义的；</p>
      <p>3.5.2 虚构事实、隐瞒真相以误导、欺骗他人的；</p>
      <p>3.5.3 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
      <p>3.5.4 利用本服务从事任何违法犯罪活动的；</p>
      <p>
        3.5.5
        制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
      </p>
      <h2>3.6 【对自己行为负责】</h2>
      <p>
        所有用户均充分了解并同意，用户必须为自己注册帐号下的一切行为负责，包括所发表的任何内容以及由此产生的任何后果。用户应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。万慧教育无法且不会对因前述风险而导致的任何损失或损害承担责任。
      </p>
      <h2>四、【万慧教育的权利义务】</h2>
      <p>4.1 【按约提供服务】</p>
      <p>
        4.1.1
        万慧教育将在约定的时间内对授权经销商提交的云学习用户申请资料进行审核，并在用户支付相应费用后在约定时间内开通本服务。若你逾期付款，万慧教育有权不予开通本服务并取消你的订单，不承担任何法律责任。
      </p>
      <p>
        4.1.2
        万慧教育向你提供清楚的万慧教育操作指引服务，并在出现相关服务问题或你操作不当时提供技术咨询服务。
      </p>
      <p>
        4.1.3
        你有权就服务质量问题向万慧教育提出意见万慧教育会尽快响应，如该问题能及时解决的，万慧教育将及时协助用户解决相应问题。
      </p>
      <p>
        4.1.4
        当本服务的功能、价格、使用方式有变更时，万慧教育会以本协议约定的通知方式通知用户。
      </p>
      <p>4.2 【保护用户信息安全】</p>
      <p>
        4.2.1
        保护用户信息安全是万慧教育的一项基本原则，万慧教育将会采取合理的措施保护用户的个人信息和邮件信息。万慧教育对相关信息采用专业加密存储与传输方式，保障用户个人信息和邮件信息的安全。
      </p>
      <p>
        4.2.2
        万慧教育将运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息和邮件信息，以免遭受未经授权的访问、使用或披露。
      </p>
      <p>
        4.2.3
        未经用户的同意，万慧教育不会向万慧教育以外的任何公司、组织和个人披露用户的个人信息和学习信息，但法律法规另有规定的除外。
      </p>
      <h2>4.3 【违约处理】</h2>
      <p>
        4.3.1
        如果万慧教育发现或收到他人举报或投诉用户违反本协议约定的，万慧教育有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规用户处以包括但不限于警告、限制或禁止使用部分或全部功能、服务暂停直至终止的处罚。万慧教育已经收取的费用不予退还。
      </p>
      <p>
        4.3.2
        你理解并同意，万慧教育有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
      </p>
      <p>
        4.3.3
        你理解并同意，因你违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，你应当独立承担责任；万慧教育因此遭受损失的，你也应当一并赔偿。
      </p>
      <h2>4.4 【服务结束及后续处理】</h2>
      <p>
        4.4.1
        服务结束是指万慧教育平台用户在本服务到期时未完成续约手续，万慧教育终止向云学习用户提供本服务。
      </p>
      <p>
        4.4.2
        你应该在本服务到期前，提前完成续约手续，否则万慧教育有权自你在云学习服务的使用期限到期日起，在不通知你的情况下终止你对本服务的使用。
      </p>
      <p>
        4.4.3
        服务结束后，万慧教育有权随时清空你因使用本服务产生的全部数据、信息等，包括但不限于已上传或参与的全部课程、考试、已收藏或获得积分等，并对你因此遭受的损失不予负责。如果你在本服务到期时无法完成续约手续或到期后决定不再继续使用本服务，请你自行提前备份相关数据。
      </p>
      <h2>五、【免责条款】</h2>
      <p>
        5.1
        你理解并同意：鉴于网络服务的特殊性或国家政策、行业规定的调整、变化等，万慧教育有权根据本服务的整体运营情况或相关运营规范、规则等，变更、中止或终止部分或全部的服务，万慧教育会以本协议约定的通知方式通知用户。
      </p>
      <p>
        5.2
        你理解并同意：为了向你提供更完善的服务，万慧教育有权定期或不定期地对提供本服务的平台或相关设备进行检修、维护、升级等，此类情况可能会造成相关服务在合理时间内中断或暂停的，若由此给你造成损失的，你同意放弃追究万慧教育的责任。
      </p>
      <p>
        5.3
        你理解并同意：万慧教育的服务是按照现有技术和条件所能达到的现状提供的。万慧教育会尽最大努力向你提供服务，确保服务的连贯性和安全性；但万慧教育不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、通信线路故障、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。所以你也同意：即使万慧教育提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为万慧教育违约，同时，由此给你造成的数据或信息丢失等损失的，你同意放弃追究万慧教育的责任。
      </p>
      <p>
        5.4
        你理解并同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，万慧教育将努力在第一时间与相关单位配合，及时进行修复，若由此给你造成损失的，你同意放弃追究万慧教育的责任。
      </p>
      <p>
        5.5
        你理解并同意：若由于以下情形导致的服务中断或受阻，万慧教育并不承担任何法律责任：
      </p>
      <p>（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
      <p>（2）你的电脑软件、系统、硬件和通信线路出现故障；</p>
      <p>（3）你操作不当；</p>
      <p>（4）你通过非万慧教育授权的方式使用本服务；</p>
      <p>（5）其他万慧教育无法控制或合理预见的情形。</p>
      <h2>六、【关于通知】</h2>
      <p>
        6.1
        万慧教育可能会以网页公告、网页提示、万慧教育平台、手机短信、常规的信件传送、你注册的本服务帐户的管理系统内发送站内信等方式中的一种或多种，向你送达关于本服务的各种规则、通知、提示等信息，该等信息一经万慧教育采取前述任何一种方式公布或发送，即视为你已经接受并同意，对你产生约束力。若你不接受的，请你书面通知万慧教育，否则视为你已经接受、同意。
      </p>
      <p>
        6.2
        若由于你提供的万慧教育平台、手机号码、通讯地址等信息错误，导致你未收到相关规则、通知、提示等信息的，你同意仍然视为你已经收到相关信息并受其约束，一切后果及责任由你自行承担。
      </p>
      <p>
        6.3
        你也同意万慧教育可以向你的电子邮件、手机号码等发送可能与本服务不相关的其他各类信息包括但不限于商业广告等。
      </p>
      <p>
        6.4
        若你有事项需要通知万慧教育的，应当按照本服务对外正式公布的联系方式书面通知万慧教育。
      </p>
      <h2>七、【知识产权】</h2>
      <p>
        7.1
        万慧教育在本服务中提供的信息内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归万慧教育所有，依法属于他人所有的除外。除另有特别声明外，万慧教育提供本服务时所依托软件的著作权、专利权及其他知识产权均归万慧教育所有。万慧教育在本服务中所使用的“万慧教育”、“万慧教育平台”、及LOGO等商业标识，其著作权或商标权归万慧教育所有。上述及其他任何万慧教育依法拥有的知识产权均受到法律保护，未经万慧教育书面许可，你不得以任何形式进行使用或创造相关衍生作品。
      </p>
      <p>
        7.2
        你仅拥有依照本协议约定合法使用本服务或相关技术接口的权利，与本服务相关的技术接口相关的著作权、专利权等相关全部权利归万慧教育所有。未经引动时代书面许可，你不得违约或违法使用，不得向任何单位或个人出售、转让、授权万慧教育的代码、技术接口及开发工具等。
      </p>
      <h2>八、【有效期限和终止】</h2>
      <p>8.1 【协议期间】</p>
      <p>除本协议约定终止之情形外，本协议将会一直有效。</p>
      <p>8.2 【终止的情形】</p>
      <p>
        8.2.1
        如你书面通知万慧教育不接受本协议或对其的修改，万慧教育有权随时终止向你提供本服务。
      </p>
      <p>
        8.2.2
        因不可抗力因素导致你无法继续使用本服务或万慧教育无法提供本服务的，万慧教育有权随时终止协议。
      </p>
      <p>
        8.2.3
        本协议约定的其他终止条件发生或实现的，万慧教育有权随时终止向你提供本服务。
      </p>
      <p>
        8.2.4
        由于你违反本协议约定，万慧教育依约终止向你提供本服务后，如你后续再直接或间接，或以他人名义注册使用本服务的，万慧教育有权直接单方面暂停或终止提供本服务。
      </p>
      <p>8.2.5 任何一方宣布破产或进入清算或解散程序。</p>
      <p>
        8.2.6
        如有新的条款双方可友好协商签订补充协议，补充协议和本协议具有同样的法律效力。
      </p>
      <p>
        8.2.7
        协议生效期间如遇国家法律、法规、国家相关部门及电信运营商有新相关文件出台或政策环境产生变化，致使本协议之签订、履行基础产生根本性变化的，任何一方可通知对方协商变更原协议，若协商不成，一方可在提前十五日通知对方的情况下终止协议并不承担任何责任。
      </p>
      <p>
        8.2.8
        如本协议或本服务因为任何原因终止的，对于你的帐户中的全部数据或你因使用本服务而存储在摩博科技服务器中的数据等任何信息，万慧教育可将该等信息保留或删除，包括服务终止前你尚未完成的任何数据。
      </p>
      <p>
        8.2.9
        如本协议或本服务因为任何原因终止的，你应自行处理好关于数据等信息的备份以及与你的用户之间的相关事项的处理等。
      </p>
      <h2>8.3 【终止后的事项】</h2>
      <p>
        本协议的终止并不影响本协议项下未完成之结算或任何一方之付款义务以及其它在终止之日前已产生的义务或权利。
      </p>
      <h2>九、【其它】</h2>
      <p>
        9.1
        本协议内容同时包括万慧教育的各项制度规范、其它本协议附件中的协议或规则、万慧教育可能不断发布的关于本服务的其他相关协议、规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，你同样应当遵守。上述内容与本协议存在冲突的，以本协议为准。一经注册或使用本协议下任何服务，即视为你已阅读并同意接受本协议及上述内容的约束。万慧教育有权在必要时单方修改本协议或上述内容，相关内容变更后，如果你继续使用本服务，即视为你已接受修改后的相关内容。如果你不接受修改后的相关内容，应当停止使用相关服务。
      </p>
      <p>9.2 本协议关于日期的规定，如无特别说明，均指自然日。</p>
      <p>
        9.3
        本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
      </p>
      <p>
        9.4
        若你和万慧教育之间发生任何纠纷或争议，首先应友好协商解决；协商不成功的，双方均同意将纠纷或争议提交本协议签订地有管辖权的人民法院解决。
      </p>
      <p>
        9.5
        本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "registerAgreement",
  props: {
    // 是否有标题
    haveTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  .content_box_title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    height: 60px;
    text-align: left;
    padding-left: 33px;
    border-bottom: 1px solid #eeeeee;
  }
}

.title_sty {
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}
.education_content_sty {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: justify;
  & > h2 {
    font-size: 16px;
    margin-top: 20px;
  }
  & > h3 {
    font-size: 14px;
    margin-top: 10px;
  }
  & > p {
    font-size: 12px;
    text-indent: 24px;
    margin-top: 10px;
  }
}
</style>